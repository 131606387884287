.container {
  display: flex;
  justify-content: center;
}

.title {
  text-align: center;
  color: white;
}

.description {
  text-align: center;
  margin-bottom: 4rem;
  color: white;
}

@media (max-width: 1024px) {
  .title {
    font-size: 1.5rem;
  }
  .description {
    margin-bottom: 2rem;
    font-size: 0.75rem;
  }
}

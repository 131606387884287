.container {
  display: flex;
  justify-content: center;
}

.title {
  text-align: center;
  color: white;
}

.description {
  text-align: center;
  margin-bottom: 4rem;
  color: white;
}

.goodToast {
  position: fixed;
  top: 5rem;
  left: 50%;
  width: 75vw;
  min-width: 15rem;
  max-width: 40rem;
  background-color: rgb(184, 252, 184);
  color: darkgreen;
  padding: 10px;
  transform: translateX(-50%);
  z-index: 9999;
  padding: 10px 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  user-select: none;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.showGoodToast {
  position: fixed;
  top: 5rem;
  left: 50%;
  width: 75vw;
  min-width: 15rem;
  max-width: 40rem;
  background-color: rgb(184, 252, 184);
  color: darkgreen;
  padding: 10px;
  transform: translateX(-50%);
  z-index: 9999;
  padding: 10px 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  user-select: none;
  transition: opacity 0.3s ease-in-out;
  opacity: 1;
}

.badToast {
  position: fixed;
  top: 5rem;
  left: 50%;
  width: 75vw;
  min-width: 15rem;
  max-width: 30rem;
  background-color: rgb(255, 180, 180);
  color: darkred;
  padding: 10px;
  transform: translateX(-50%);
  z-index: 9999;
  padding: 10px 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  user-select: none;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.content {
  text-align: center;
}

.showBadToast {
  position: fixed;
  top: 5rem;
  left: 50%;
  width: 75vw;
  min-width: 15rem;
  max-width: 30rem;
  background-color: rgb(255, 180, 180);
  color: darkred;
  padding: 10px;
  transform: translateX(-50%);
  z-index: 9999;
  padding: 10px 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  user-select: none;
  transition: opacity 0.3s ease-in-out;
  opacity: 1;
}

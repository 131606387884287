.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  display: flex;
  margin-top: 2rem;
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  padding: 2px 16px;
  border-radius: 5px; /* 5px rounded corners */
  width: 50rem;
  height: 10rem;
  background-color: white;
}

.font {
  color: white;
}

.card:hover {
  background-color: #b8ccd9;
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.profile {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
}

.truncatedSummary {
  width: 40rem;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
}

.jobTitle {
  width: 40rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 1024px) {
  .card {
    display: flex;
    margin-top: 2rem;
    /* Add shadows to create the "card" effect */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    padding: 2px 16px;
    border-radius: 5px; /* 5px rounded corners */
    width: 75vw;
    min-width: 15rem;
    height: 10rem;
    background-color: white;
  }

  .truncatedSummary {
    width: 30vw;
    min-width: 5rem;
    height: 5rem;
    word-wrap: break-word;
    overflow: scroll;
    text-overflow: ellipsis;
  }

  .jobTitle {
    width: 30vw;
    min-width: 5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.pagination a {
  padding: 10px;
  margin: 0 5px;
  text-decoration: none;
  color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.pagination a.active {
  background-color: #b8ccd9;
  color: #fff;
  border-color: #007bff;
}

.pagination a:hover {
  background-color: #ddd;
}

.icon {
  width: 1rem;
  height: auto;
}

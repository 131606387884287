.card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  padding: 2rem 4rem;
  margin-bottom: 2rem;
  border-radius: 5px; /* 5px rounded corners */
  width: 60rem;
  height: 100%;
  background-color: white;
}

.container {
  display: flex;
}

.description {
  flex: 2;
  margin: 10px;
}

.pricing {
  flex: 1;
  margin: 10px;
  text-align: center;
}

.price {
  font-size: 3em; /* Adjust as needed */
  font-weight: 500;
}

.period {
  font-size: 1em; /* Adjust as needed */
  color: grey; /* Adjust as needed */
}

.button {
  color: white;
  padding: 15px 20px;
  border-radius: 5px;
  background-color: #1c3c64;
  border: none;
  width: 100%;
  outline: none;
  font-size: 16px;
  cursor: pointer;
  opacity: 0.9;
  transition: all 0.3s ease;
}

.button:hover {
  background-color: #597087;
}

.button:active {
  background-color: #3e758e;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}

.line {
  flex: 1;
  border: none;
  border-top: 2px solid black; /* Change the color and thickness */
  margin: 0 10px; /* Adjust the space around the text */
}

.whatYouGet {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.splitHalf {
  display: flex;
}

.column {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin-left: 1rem;
  width: 100%;
  box-sizing: border-box;
}

.text {
  margin: 5px 0;
  width: 20rem;
}

@media (max-width: 1024px) {
  .card {
    margin-top: 2rem;
    /* Add shadows to create the "card" effect */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    padding: 2px 16px;
    border-radius: 5px; /* 5px rounded corners */
    max-width: 60rem;
    width: 90vw;
    height: 100%;
    background-color: white;
  }

  .price {
    font-size: 3em; /* Adjust as needed */
    font-weight: 500;
  }

  .period {
    font-size: 1em; /* Adjust as needed */
    color: grey; /* Adjust as needed */
  }

  .pricing {
    flex: 1;
    text-align: center;
  }

  .container {
    display: flex;
    flex-direction: column;
  }

  .text {
    margin: 5px 0;
    width: 30vw;
  }
}

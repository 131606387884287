.title {
  margin-left: 2rem;
  color: white;
}

.background {
  background-color: #1c3f60;
  min-height: 100vh;
  display: flex;
}

.logo {
  width: 5rem;
  display: inline;
  margin-left: 5rem;
  padding-top: 2rem;
}

.table {
  flex: 1;
  background-color: #1c3f60;
}

@media (max-width: 1024px) {
  .title {
    margin-left: 2rem;
    font-size: 1.5rem;
  }
}

@media (max-width: 767px) {
  .title {
    margin-left: 2rem;
    font-size: 1rem;
  }
}

.container {
  display: flex;
  justify-content: center;
}

.title {
  text-align: center;
  color: white;
}

.accountText {
  text-align: center;
  margin-bottom: 4rem;
  color: white;
}

.link {
  color: white;
  text-decoration: none;
}

/* Navigation style */

.nav {
  display: flex;
  justify-content: space-between;
}

.navBarRight {
  list-style-type: none;
  display: flex;
  margin-right: 5rem;
}

.navBarLeft {
  list-style-type: none;
  display: flex;
  margin-left: 5rem;
}

.button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: white;
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: #b8ccd9;
}

.navItems {
  margin-right: 1rem;
}

@media (max-width: 767px) {
  .nav {
    display: flex;
    justify-content: center;
  }

  .button {
    padding: 10px 15px;
    font-size: 16px;
    background-color: white;
    color: black;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .navItems {
    margin-right: 0.5rem;
  }
}

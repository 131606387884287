.card {
  margin-top: 2rem;
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  padding: 2px 16px;
  border-radius: 5px; /* 5px rounded corners */
  width: 25rem;
  height: 20rem;
  background-color: white;
  margin: 0 5rem;
}

.item {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.button {
  color: white;
  padding: 15px 20px;
  border-radius: 5px;
  background-color: #1c3c64;
  border: none;
  width: 100%;
  outline: none;
  font-size: 16px;
  cursor: pointer;
  opacity: 0.9;
  transition: all 0.3s ease;
}

.button:hover {
  background-color: #597087;
}

.button:active {
  background-color: #3e758e;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}

.input {
  padding: 15px 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
  outline: none;
  font-size: 14px;
}

.h3 {
  text-align: center;
}

@media (max-width: 1024px) {
  .card {
    margin-top: 2rem;
    /* Add shadows to create the "card" effect */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    padding: 2px 16px;
    border-radius: 5px; /* 5px rounded corners */
    max-width: 25rem;
    width: 50vw;
    max-height: 20rem;
    height: 40vh;
    background-color: white;
    margin: 0 5rem;
  }
}

.card {
  display: flex;
  margin-top: 2rem;
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  padding: 2px 16px;
  border-radius: 5px; /* 5px rounded corners */
  width: 25rem;
  height: 10rem;
  background-color: white;
  margin: 0 5rem;
}

.card:hover {
  background-color: #b8ccd9;
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

@media (max-width: 1024px) {
  .card {
    display: flex;
    margin-top: 2rem;
    /* Add shadows to create the "card" effect */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    padding: 2px 16px;
    border-radius: 5px; /* 5px rounded corners */
    max-width: 25rem;
    width: 30vw;
    height: 10rem;
    background-color: white;
    margin: 0 1rem;
  }

  .title {
    font-size: 1.25rem;
  }
}

@media (max-width: 767px) {
  .description {
    font-size: 0.75rem;
  }
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  padding: 2rem 4rem;
  margin-bottom: 2rem;
  border-radius: 5px;
  width: 50rem;
  height: 100%;
  background-color: white;
}

.item {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.imageItem {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

img {
  width: 100px;
}

.input {
  padding: 15px 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
  outline: none;
  font-size: 14px;
  margin-bottom: 2rem;
  width: 100%;
  word-wrap: break-word;
  overflow: hidden;
}

.label {
  margin-right: 1rem;
  width: 10rem;
  overflow: auto;
}

.skills {
  margin-right: 1rem;
}

#profile {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
}

.icon {
  width: 1.5rem;
  vertical-align: middle;
}

.icon:hover {
  cursor: pointer;
}

.approveButton {
  color: black;
  padding: 1rem 1rem;
  background-color: #dddddd;
  border: none;
  outline: none;
  font-size: 1rem;
  cursor: pointer;
  opacity: 0.9;
  transition: all 0.3s ease;
  border-radius: 5px;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.4);
  margin-right: 2rem;
}

.approveButton:hover {
  background-color: #22ff00;
}

.approveButton:active {
  background-color: #3e758e;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}

.denyButton {
  color: black;
  padding: 1rem 1rem;
  background-color: #dddddd;
  border: none;
  outline: none;
  font-size: 1rem;
  cursor: pointer;
  opacity: 0.9;
  transition: all 0.3s ease;
  border-radius: 5px;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.4);
}

.denyButton:hover {
  background-color: #ff0000;
}

.denyButton:active {
  background-color: #3e758e;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}

#summary {
  width: 100%; /* Occupy the full width of the container */
  padding: 10px; /* Add padding for spacing */
  border-radius: 5px; /* Add rounded corners */
  box-sizing: border-box; /* Include padding and border in the width */
  resize: none;
}

.jobTitle {
  font-size: 1.5rem;
  text-align: center;
}

.title {
  white-space: nowrap;
  overflow: auto;
}

@media (max-width: 1024px) {
  .card {
    /* Add shadows to create the "card" effect */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    padding: 2rem 2rem;
    margin-bottom: 2rem;
    border-radius: 5px; /* 5px rounded corners */
    width: 75vw;
    min-width: 18rem;
    height: 100%;
    background-color: white;
  }

  .input {
    padding: 15px 20px;
    border: 1px solid #ccc;
    font-size: 14px;
    word-wrap: break-word;
    overflow: hidden;
  }

  .skills {
    margin-right: 1rem;
    font-size: 12px;
    height: 10rem;
    overflow: scroll;
  }

  #profile {
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 20px;
  }

  .icon {
    width: 1.5rem;
    vertical-align: middle;
  }

  .jobTitle {
    font-size: 0.75rem;
    text-align: center;
  }
}

@media (max-width: 767px) {
  #profile {
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 20px;
  }
}

.container {
  display: flex;
  justify-content: center;
}

.title {
  text-align: center;
  color: white;
}

.description {
  text-align: center;
  margin-bottom: 4rem;
  color: white;
}

.link {
  color: white;
  text-decoration: none;
}

@media (max-width: 1024px) {
  .title {
    font-size: 1.5rem;
  }

  .description {
    text-align: center;
    margin-bottom: 4rem;
    color: white;
    font-size: 1rem;
  }
}

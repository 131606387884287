.card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  padding: 2rem 4rem;
  margin-bottom: 2rem;
  border-radius: 5px; /* 5px rounded corners */
  width: 50rem;
  height: 100%;
  background-color: white;
}

.item {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.imageItem {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.jobTitle {
  padding: 15px 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
  outline: none;
  font-size: 2rem;
  margin-bottom: 2rem;
}

img {
  width: 100px;
}

.button {
  color: white;
  padding: 15px 20px;
  border-radius: 5px;
  background-color: #1c3c64;
  border: none;
  width: 100%;
  outline: none;
  font-size: 16px;
  cursor: pointer;
  opacity: 0.9;
  transition: all 0.3s ease;
}

.button:hover {
  background-color: #597087;
}

.button:active {
  background-color: #3e758e;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}

.input {
  padding: 15px 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
  outline: none;
  font-size: 14px;
  margin-right: 2rem;
  margin-bottom: 2rem;
}

#summary {
  width: 100%; /* Occupy the full width of the container */
  padding: 10px; /* Add padding for spacing */
  border-radius: 5px; /* Add rounded corners */
  box-sizing: border-box; /* Include padding and border in the width */
  resize: none;
}

#featuredSummaryCounter {
  font-size: 12px;
  margin-top: -20px;
  margin-right: 2px;
  text-align: right;
  margin-bottom: 2rem;
}

#profile {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
}

.icon {
  width: 32px;
}

.icon:hover {
  cursor: pointer;
}

.statusRed {
  background-color: rgb(255, 180, 180);
  padding: 1.5rem;
  width: fit-content;
  border-radius: 5px;
  color: darkred;
  user-select: none;
  margin-bottom: 2rem;
}

.statusOrange {
  background-color: rgb(255, 230, 180);
  padding: 1.5rem;
  width: fit-content;
  border-radius: 5px;
  color: rgb(200, 110, 0);
  user-select: none;
  margin-bottom: 2rem;
}

.statusGrey {
  background-color: rgb(240, 240, 240);
  padding: 1.5rem;
  width: fit-content;
  border-radius: 5px;
  color: rgb(110, 110, 110);
  user-select: none;
  margin-bottom: 2rem;
}

.statusGreen {
  background-color: rgb(180, 255, 180);
  padding: 1.5rem;
  width: fit-content;
  border-radius: 5px;
  color: darkgreen;
  user-select: none;
  margin-bottom: 2rem;
}

.statusAlign {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@media (max-width: 1024px) {
  .card {
    /* Add shadows to create the "card" effect */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    padding: 2rem 4rem;
    margin-bottom: 2rem;
    border-radius: 5px; /* 5px rounded corners */
    max-width: 50rem;
    width: 50vw;
    height: 100%;
    background-color: white;
  }

  .imageItem {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  }

  .jobTitle {
    padding: 15px 20px;
    border-radius: 5px;
    border: 1px solid #ccc;
    outline: none;
    font-size: 3.5vw;
    margin-bottom: 2rem;
  }

  img {
    width: 100px;
  }

  .button {
    color: white;
    padding: 15px 20px;
    border-radius: 5px;
    background-color: #1c3c64;
    border: none;
    width: 100%;
    outline: none;
    font-size: 16px;
    cursor: pointer;
    opacity: 0.9;
    transition: all 0.3s ease;
  }

  .button:hover {
    background-color: #597087;
  }

  .button:active {
    background-color: #3e758e;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  .input {
    padding: 15px 20px;
    border-radius: 5px;
    border: 1px solid #ccc;
    outline: none;
    font-size: 2vw;
    margin-right: 0.5rem;
    margin-bottom: 2rem;
  }

  #summary {
    width: 100%; /* Occupy the full width of the container */
    padding: 10px; /* Add padding for spacing */
    border-radius: 5px; /* Add rounded corners */
    box-sizing: border-box; /* Include padding and border in the width */
    resize: none;
  }

  #featuredSummaryCounter {
    font-size: 12px;
    margin-top: -20px;
    margin-right: 2px;
    text-align: right;
    margin-bottom: 2rem;
  }

  #profile {
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 20px;
  }

  .skills {
    font-size: 1rem;
    height: 10rem;
    overflow: scroll;
  }
}

@media (max-width: 767px) {
  #profile {
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 0px;
    margin-left: 20px;
  }

  .input {
    border-radius: 5px;
    border: 1px solid #ccc;
    outline: none;
    font-size: 2.5vw;
    margin-right: 0.5rem;
    margin-bottom: 2rem;
  }
}
